import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies

import Logos from './index';
import styles from './styles.module.css';

export default function LogosBlock({ logos, ...props }) {
  return (
    <section className={styles.logosBlock}>
      <Logos logos={logos} />
    </section>
  );
}
