import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.css';

const PageBackground = ({ color }) => (
  <div style={{backgroundColor: color}} className={styles.pageBackground}>
    <svg style={{backgroundColor: color}} viewBox="0 0 1175 722">
      <use xlinkHref="/images/bg_white_1.svg#path" />
    </svg>
    <div style={{backgroundColor: color}} className={styles.bgMiddle} />
    <svg style={{backgroundColor: color}} viewBox="0 0 1375 1267">
      <use xlinkHref="/images/bg_white_2.svg#path" />
    </svg>
  </div>
);

export default function Page(props) {
  const { className, children, bgColor, ...rest } = props;

  return (
    <div className={styles.bgContainer}>
      <div style={{backgroundColor: bgColor}} className={styles.bgLeft} />
      <div style={{backgroundColor: bgColor}} className={styles.bgRight} />
      <div className={styles.innerBgContainer}>
        <PageBackground color={bgColor} />
        <section className={classNames(className, styles.container)} {...rest}>
          <div className={styles.inner}>{children}</div>
        </section>
      </div>
    </div>
  );
}
